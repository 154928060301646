<template>
    <div class="section-wrapper">
        <BreadCumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <b-overlay :show="loading">
            <div class="form-wrapper btb-return-db">
                <b-card :title="$t('teaGardenDashboard.tea_production_dashboard')">
                    <b-row>
                        <b-col cols="12">
                            <b-card-text>
                                <b-row>
                                    <b-col sm="6">
                                        <b-form-group label-for="year">
                                            <template v-slot:label>
                                                {{$t('globalTrans.year')}}
                                            </template>
                                            <b-form-select plain v-model="search.year" :options="yearList" @change="searchData" id="year">
                                                <template v-slot:first>
                                                    <b-form-select-option disabled :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-form-group label-for="month">
                                            <template v-slot:label>
                                                {{$t('globalTrans.month')}}
                                            </template>
                                            <b-form-select plain v-model="search.month" :options="monthList" @change="searchData" id="month">
                                                <template v-slot:first>
                                                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="mt-1 tea-garden-header">
                    <b-row>
                        <b-col sm="6">
                            <a>
                                <b-card class="info-box text-dark">
                                    <p>{{ $t('teaGardenDashboard.total_productions') }}</p>
                                    <h2>{{ $n(totalInfo.total_productions) }}</h2>
                                </b-card>
                            </a>
                        </b-col>
                        <b-col sm="6">
                            <a>
                                <b-card class="info-box text-dark">
                                    <p>{{ $t('teaGardenDashboard.total_sales') }}</p>
                                    <h2>{{ $n(totalInfo.total_sales) }}</h2>
                                </b-card>
                            </a>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import BreadCumb from '@/components/BreadCumb.vue'
import { helpers } from '@/utils/helper-functions'
export default {
  name: 'ReturnDashboard',
  props: [],
  components: {
    BreadCumb
  },
  data () {
    return {
        unitLoad: false,
        search: {
            year: 0,
            month: 0
        },
        totalInfo: {
            total_productions: 0,
            total_sales: 0
        },
        loading: false
    }
  },
  computed: {
    yearList () {
      return helpers.getYearList()
    },
    monthList: function () {
      return this.$store.state.commonObj.monthList.map(el => {
        return Object.assign(el, {}, { text: this.$i18n.locale === 'en' ? el.text_en : el.text_bn })
      })
    }
  },
  created () {
    this.search.year = new Date().getFullYear()
    this.loadData()
  },
  methods: {
    searchData () {
        this.loadData()
    },
    async loadData () {
        this.loading = true
        const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/tea-production-dashboard', this.search)
        if (result.success) {
            this.totalInfo = result.data
        }
        this.loading = false
    }
  }
}
</script>
<style>
.btb-return-db .tea-garden-header{
    padding: 1rem;
    background-image: linear-gradient(to right, #11998ee5, #38ef7ee9), url(../../../../../assets/images/tea-gardens-two.jpg);
    background-position: center;
    background-size: cover;
    border: none;
    border-radius: 5px;
}
.btb-return-db .tea-garden-header .info-box{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    margin: 0;
    backdrop-filter: blur(4px) saturate(152%);
    -webkit-backdrop-filter: blur(4px) saturate(152%);
    background-color: rgba(255, 255, 255, 0.53);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.btb-return-db .tea-return-wrapper{
    padding: 0 3rem;
    margin-top: -5rem;
}
.btb-return-db .tea-return-wrapper .card{
    position: relative;
}
.btb-return-db .tea-return-wrapper .card img{
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.btb-return-db .tea-return-wrapper .card .return-badge{
    position: absolute;
    bottom: -17px;
    right: 15px;
    color: #fff;
    /* padding: 0 5px; */
    padding: 6px;
    z-index: 1;
    border-radius: 2px;
    background: #06b98f;
}
</style>
